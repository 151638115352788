@import "../../_app.scss";
/**/
input:focus:invalid:focus,
textarea:focus:invalid:focus,
select:focus:invalid:focus {
  border-color:#c8c8c8;
  -moz-box-shadow:none;
  -webkit-box-shadow:none;
  box-shadow:none;
  color:#555;
}

.hidden-el {
  height: 1px !important;
  padding: 0 !important;
  width: 1px;
  /* margin: -3000px; */
  left: -3000px;
  position: absolute;
}
@media (max-width: 610px){

  .header
  {
    padding-top: 10px;
  }

  .header ul
  {
    margin-bottom: 5px;
  }
  #form-signup, .form-horizontal .control-group:last-child
  {
    margin-bottom: 0;
  }
  #form-signup, .form-horizontal .control-group:last-child
  {
    margin-bottom: 10px;
  }
  hr
  {
    display:none
  }
  #form-login .control-group.form-inline .controls label {
    display: none;
  }
  .form-horizontal .control-label, .form-horizontal .controls, .header ul.inline
  {
    text-align: right;
  }

  .control-group input[type="text"],
  .control-group input[type="email"],
  .control-group input[type="password"] {
    width: 100%;
    height:50px;
    font-size: 25px;
    line-height: 24px;
  }

  .form-horizontal .form-inline .controls
  {
    width:100%;
  }
  .btn-primary{
    float: none !important;
    width:95%;
    margin: 0 auto;
    height: 60px;
    font-size: 25px;
  }
  #form-login .control-group.form-inline .controls {
    max-width:100%;
  }
  form + .panel {
    display: none;
  }
  .form-horizontal .controls {
    margin-left: 0;
  }
  .control-label{
    display: none;
  }
  .content h2 {
    display: none;
  }
  .content h6 {
    display: none;
  }
  .footer a {
    font-size: 14px;
    line-height: 20px;
  }
  .footer ul li:first-child a{
    font-size: 18px;
    line-height: 50px;
  }
}

